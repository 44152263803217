import React from 'react'
import PropTypes from 'prop-types'
import * as b from "../components/bootstrap.module.css"
import Translate from './translate';

const AdvDisColumn = ({ items, type }) => items ? (
  <div className={[b.dFlex, b.colLg6, b.mb3, b.mbLg0].join(' ')}>
    <div className={[type, b.w100, b.p3].join(' ')}>
      <span className={[b.mb3,'tp-name'].join(' ')}>{type === 'advantages' ? <Translate tKey="Advantages" /> : <Translate tKey="Disadvantages" />}</span>
      {items ? items.split('\n').map((item, i) => item.length > 10 ? (
          <li key={i} className={b.listUnstyled}>
           <p className={[b.mt0,b.pl2].join(' ')}>{item.replace(/<[^>]+>/ig, '')}</p>
          </li>
        ) : null
      ) : null}
    </div>
  </div>
) : null;

AdvDisColumn.propTypes = {
  items: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

const AdvDis = ({ advantages, disadvantages, children }) => (
  <>
    {children}
    <div className={[b.row, b.justifyContentBetween, b.mt3,'blackToWhite'].join(' ')}>
      <AdvDisColumn items={advantages} type="advantages" />
      <AdvDisColumn items={disadvantages} type="disadvantages" />
    </div>
  </>
)

AdvDis.propTypes = {
  advantages: PropTypes.string.isRequired,
  disadvantages: PropTypes.string.isRequired,
}

export default AdvDis;